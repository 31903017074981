<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('meetings')"
                        :isNewButton="false"
                        :isColumns="true"
                        :actions="subMenu"
                        :isImportButton="true"
                        @import-button-click="importData"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('meetings')"
                              :isNewButton="false"
                              :isColumns="true"
                              :isImportButton="true"
                              @import-button-click="importData"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :exportExcel="true"
                @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox
                                v-model="datatable.queryParams.filter.semester_id"></semesters-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('meeting_type')">
                            <parameter-selectbox
                                code="graduate_application_meeting_type"
                                v-model="datatable.queryParams.filter.meeting_type"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="meetingsModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('meetings').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <meetings-form :graduateApplicationId="graduateApplicationId"
                                   v-if="formProcess=='meetings'"/>
                </template>
            </CommonModal>
            <CommonModal ref="importModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="closeModal" :footer="true" :footer-class="'justify-content-center'">
                <template v-slot:CommonModalTitle>
                    <div>{{ formProcess == 'import' ? $t('import').toUpper() : '' }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="uploadForm">
                        <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('excel_file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file
                                        v-model="importFile"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :placeholder="$t('select_file')"
                                        :drop-placeholder="$t('drop_file')"
                                        ref="fileInput"
                                        multiple></b-form-file>
                                    <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                                </div>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </b-form-group>
                        </ValidationProvider>
                    </ValidationObserver>
                </template>
                <template v-slot:CommonModalFooter>
                    <b-form-group>
                        <b-button variant="primary" @click="importExcel">{{ $t('save') }}</b-button>
                    </b-form-group>
                </template>
            </CommonModal>
            <CommonModal ref="responsiblePersonModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('responsible_person_update').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <b-col md="12" >
                        <b-form-group :label="$t('responsible_person')">
                            <staff-auto-complete
                                v-model="selectedCallUserId"
                                :valueObject="selectedObject"
                            ></staff-auto-complete>
                        </b-form-group>
                    </b-col>
                    <b-button type="submit"
                              variant="primary"
                              @click="responsiblePersonUpdate"
                              class="btn-lg btn-inline d-flex justify-content-center align-items-center">
                        {{ $t('save').toUpper() }}
                    </b-button>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete.vue";
import StaffAutoSuggest from "@/components/interactive-fields/StaffAutoSuggest.vue";

//Pages
import MeetingsForm from "../MeetingsForm";

// Services
import GraduateApplicationService from "@/services/GraduateApplicationService";

// Others
import {ValidationObserver, ValidationProvider} from "vee-validate"
import qs from 'qs'
import moment from "moment";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        SemestersSelectbox,
        ParameterSelectbox,
        MeetingsForm,
        StaffAutoComplete,
        StaffAutoSuggest,

        ValidationProvider,
        ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t('graduate_applications')+' | '+this.$t('meetings')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "graduateapplication_show",
                                callback: (row) => {
                                    this.meetingsShow(row.graduate_application_id)
                                }
                            },
                            {
                                text: this.$t('responsible_person_update'),
                                class: 'ri-file-user-fill align-middle top-minus-1 mr-3 text-muted',
                                permission: "graduateapplication_show",
                                callback: (row) => {
                                    this.responsiblePersonModal(row)
                                }
                            },


                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: false,
                        sortable: true
                    },
                    {
                        label: this.toUpperCase('national_id'),
                        field: 'graduate_application.national_id',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'graduate_application.name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'graduate_application.surname',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('telephone'),
                        field: 'graduate_application.phone',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('email'),
                        field: 'graduate_application.email',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('application_completion_status'),
                        field: ('graduate_application_completion_status'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('calling_status'),
                        field: ('status_name'),
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('calling_next_date'),
                        field: 'next_call_date',
                        hidden: false,
                        formatFn: function (date) {
                            return moment(date).format("DD.MM.YYYY")
                        }
                    },
                    {
                        label: this.toUpperCase('meeting_content'),
                        field: 'description',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('responsible_person'),
                        field: 'responsiblePerson',
                        hidden: false,
                        formatFn:(value)=>{
                            if (value && value.name && value.surname) {
                                return value.name + ' ' + value.surname;
                            } else {
                                return '';
                            }
                        }
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            nationalIdForUpdate: 0,
            pinForUpdate: 0,
            graduateApplicationId: 0,
            form: {},
            subMenu: [
                {
                    text: this.$t('applications'),
                    class: 'ri-file-text-line',
                    permission: 'graduateapplication_index',
                    callback: () => {
                        this.$router.push('/graduate/applications');
                    }
                },
                {
                    text: this.$t('meetings'),
                    class: 'ri-calendar-todo-line',
                    permission: 'graduateapplicationmeeting_index',
                    callback: () => {
                        return false;
                    }
                },
                {
                    text: this.$t('meeting_report'),
                    class: 'ri-folder-chart-line',
                    permission: 'graduateapplicationmeeting_index',
                    callback: () => {
                        this.$router.push('/graduate/application/meetings/report');
                    }
                },
            ],
            isAdmin: false,
            currentUserId: null,
            importFile: null,
            selectedCallUserId:null,
            selectedObject:{},
            selectedItem:{}
        }
    },
    created() {
        this.$store.dispatch("auth/initUser");
        let user = this.$store.getters['auth/getUser']
        this.isAdmin = this.$store.getters['auth/isAdmin']
        this.currentUserId = user.id
        this.filterSet();
    },
    methods: {
        filterSet() {
            let userFilter = !this.isAdmin ? {call_user_id: this.currentUserId} : false
            this.datatable.queryParams.filter = {
                ...userFilter,
                meeting_type: null
            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return GraduateApplicationService.meetingsGetAll(config)
                                             .then((response) => {
                                                 this.datatable.rows = response.data.data
                                                 this.datatable.total = response.data.pagination.total
                                             })
                                             .finally(() => {
                                                 this.datatable.isLoading = false;
                                             });
        },

        formClear() {
            this.graduateApplicationId = 0
            this.formProcess = null
        },

        meetingsShow(graduateApplicationId) {
            this.graduateApplicationId = graduateApplicationId
            this.formProcess = 'meetings'
            this.$refs.meetingsModal.$refs.commonModal.show()
        },

        importData() {
            this.formProcess = 'import'
            this.$refs.importModal.$refs.commonModal.show()
        },

        async importExcel() {
            const isValid = await this.$refs.uploadForm.validate();
            if(isValid) {
                let formData = new FormData();
                formData.append('file',this.importFile[0])
                GraduateApplicationService.excelImportMeeting(formData).then(response => {
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.file) {
                            this.$refs.uploadForm.errors.file.push(e.data.errors.file[0]);
                        }
                    }
                })
            }
        },
        closeModal() {
            this.formProcess = null
            this.$refs.importModal.$refs.commonModal.hide()
            this.getRows()
        },
        exportExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            GraduateApplicationService.excelExportMeeting(config)
                                 .then(res => this._downloadFile(res, this.$t("meetings") + '.xlsx'))
                                 .catch(err => this.showErrors(err))
        },
        responsiblePersonModal(row){
            this.formProcess = 'responsiblePersonModal'
            this.selectedCallUserId=row.responsiblePerson!=null?row.responsiblePerson.user_id:null
             if(this.selectedCallUserId){
              this.selectedObject={value:row.responsiblePerson.user_id,text:row.responsiblePerson.name+' '+row.responsiblePerson.surname}
             }
            this.selectedItem=row
            this.$refs.responsiblePersonModal.$refs.commonModal.show()
        },
        responsiblePersonUpdate() {
              let user_id = this.selectedCallUserId
              let id = this.selectedItem.id
           const config = {
                    description:this.selectedItem.description,
                    type:this.selectedItem.type,
                    next_call_date:this.selectedItem.next_call_date,
                    status:this.selectedItem.status,
                    responsible_person_id:this.selectedCallUserId
            };
           return  GraduateApplicationService.updateMeeting(id,config)
                .then(res =>{
                        this.$toast.success(this.$t('api.' + res.data.message)),
                            this.$refs.responsiblePersonModal.$refs.commonModal.hide()
                })
                .catch(err => this.showErrors(err))
        }

    }
}
</script>

